
<template>
  <div class="mine">
    <van-nav-bar  :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
      class="nav" @click-right="go">
      <template #title>
        <p class="title">我的</p>
      </template>
    </van-nav-bar>
    <div class="main-body" v-if="userInfo">
      <div class="user-info-box">
        <div class="info-top">
          <div class="info-top-left">

              <van-image :src="userInfo.avatar" radius="50%" fit="fill" class="lazy_img">
              </van-image>
            <div class='base-info'>
              <div class='info-name'>{{ userInfo.username }}</div>
              <div class="info-phone">{{ userInfo.phone }}</div>
            </div>
          </div>
          <div class="info-top-right" v-if="userInfo.isValid">
            <van-image :src="require('../../assets/cert.png')" fit="fill" width="18px"></van-image>
            <span >已实名认证</span>
          </div>
        </div>
        <div class="info-bottom">
          <div class="blockchain-info">
            <div class="block-address">区块链地址：</div>
            <div class="address">{{ (userInfo.hashCode == null || userInfo.hashCode == '')? '暂无区块链地址' :  userInfo.hashCode}}</div>
          </div>
          <van-image v-show="!(userInfo.hashCode == null || userInfo.hashCode == '')" @click="copyAddress(userInfo.hashCode)" class="block-img"
                     :src="require('../../assets/copy.png')" width="25px" fit="fill"></van-image>
        </div>
      </div>
      <div class="info-middle-box">
        <router-link tag="div" to="/message" class="middle-router-box">
          <van-image class="middle-img" :src="require('../../assets/mine/icon_mine_message.png')" fit="fill"></van-image>
          <div class="item-middle">消息</div>
        </router-link>
        <router-link tag="div" to="/order" class="middle-router-box">
          <van-image class="middle-img" :src="require('../../assets/mine/icon_mine_order.png')" fit="fill"></van-image>
          <div class="item-middle">订单</div>
        </router-link>
      </div>
      <div class="bottom-box">
        <div v-if="(productInfo.count > 0)" class="bottom-box-info">我的徽章（{{ productInfo.count }}）</div>
        <div v-if="(productInfo.count <= 0)" class="bottom-box-info">我的徽章（0）</div>
        <div class="my-nft-list">
          <section class="goods-box search-wrap">
            <van-list v-if="(firstShow || productList.length > 0)" v-model:loading="loading" v-model:error="error" :finished="finished" finished-text="已经到底了"
                      error-text="加载出错,请重新加载" @load="onLoad">
              <ul class="goods-content">
                <li v-for="(item) in productList" :key="item.id">
                  <div @click="toProductDetail(item)" class="itemDiv">


                   <img v-show="item.isRead === false" class="icon-new"  :src="require('../../assets/common/icon_new.png')"/>


                    <div v-show="(item.number == null || item.number == '')" class="send-in">
                      <van-image  class="icon-" :src="require('../../assets/mine/icon_mine_sending.png')" >
                      </van-image>
                      <p>发放中</p>

                    </div>
                    <van-image class="product-img" fit="cover" :src="item.image" radius="8px 8px 0 0 ">
                    </van-image>
                    <div class='goods-div'>
                      <p class="goods-div-top">
                        {{ item.productName }}
                      </p>
                      <div class="goods-div-bottom">


                        <div class="left-chain-logo">
                          <van-image :src="require('../../assets/mine/icon_mine_chain.png')" class="goods-bottom-img"  >
                          </van-image>
                        </div>
                        <p class="ntf-number">{{ (item.number == null || item.number == '') ? '编码生成中' : item.number }}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
            <div v-else class="nodata">
              <img src="@/assets/common/icon_nodata.png" alt="">
              暂无徽章
            </div>
          </section>
        </div>


      </div>

      <div class="botton-introduce">
        <div>
          <img class="chain-icon" src="../../assets/icons/chain_logo_big.png">
        </div>
        <div class="chain-botton">提供区块链技术服务支持</div>
      </div>
    </div>

    </div>



  <Certification></Certification>
  <tabbar></tabbar>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import { clearToast, createNonceStr, showFailToast, showLoading } from "../../utils/util";
export default {
  name: "mine",
  components: {  Certification },
  setup() {
    const $router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      userInfo: {},
      productInfo:{},
      productList: [],
      loadingMore: false,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      offset: 0,
      limit: 1000,
      firstShow:true
    })
    const go = (() => {
      console.log($router)
      $router.push("/nftDetail/share")
    })
    const toProductDetail = ((data) => {

      if(!state.userInfo.isValid){
          showFailToast('为了您的区块链信息安全，请先进行实名认证！');
          setTimeout(
            ()=>{
              $router.push({
                name: "approve",
                params: {}
              })
            },1000)

      }else if( data.number == null || data.number == ''){
          showFailToast('藏品发放中,请稍后再试!')
      }else{
        data.userName = state.userInfo.username
        $router.push({
          path: "/nftDetail",
          query: { productDetail: JSON.stringify(data), isShow: true }
        })
      }

    })
    const goBack = (() => {
      $router.back(-1)
    })
    proxy.$http.get("/wenwu-user/user").then(res => {
      state.userInfo = res.data.data;
      console.log(res.data.data)
    })
    const onLoad = (() => {
      proxy.$http.get("/wenwu-order/order/collections?type=2&offset=" + state.offset + "&limit=" + state.limit).then(res => {

        if (res.data.code == 200) {
          state.firstShow = false;

          clearToast()
          state.productInfo=res.data.data
          state.productList.push(...res.data.data.list);
          console.log(state.productList)

          state.finished = false
          state.loading = false
          if (res.data.data.list.length < 5) {
            state.finished = true
          } else {
            state.offset += state.limit
          }
        } else {
          showFailToast(res.data.message)
          state.error = true;
          state.finished = false;
          state.loading = false;
        }
      }).catch((err) => {
        showFailToast(err.message)
      })
    })
    const copyAddress = ((str) => {
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = str;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove()
      proxy.$toast.success('复制成功');
    })
    return {
      go,
      goBack,
      ...toRefs(state),
      copyAddress,
      toProductDetail,
      onLoad
    }
  }
}
</script>

<style scoped lang="scss" src="./index.scss">

</style>


